<template>
  <div class="js-common-wrap js-system-usage-installAccount">
    <div class="js-common-head">
     <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">装机量</label>
      </div>
      <!-- <el-button type="primary" size="small" @click="handleAuthorize">授权管理</el-button> -->
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据">
        <el-table-column prop="deviceId" label="设备ID"></el-table-column>
        <el-table-column prop="name" label="用户名"></el-table-column>
        <el-table-column prop="gmtUpdated" label="上次装机时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span v-if="scope.row.status">已添加</span>
            <span class="text-btn" v-else @click="handleAdd(scope.row)">未添加</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
    <usage-add v-model="usageAddDialogVisible" @usageAdd="handleUsageAdd"></usage-add>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import UsageAdd from '@/components/UsageAdd'
export default {
  name: '',
  components: {
    CommonPagination,
    UsageAdd
  },
  data() {
    return {
      tableData: [],
      usageAddDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      currentItem: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/usage/devices',
        params: {
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    handleAuthorize(){
      // this.$router.push({
      //   path: `/system/usage/authorize/${parseInt(Math.random() * 190)}`,
      // })
    },
    handleAdd(row){
      this.usageAddDialogVisible = true
      this.currentItem = row
    },
    handleUsageAdd(){
      this.$axios({
        method: 'post',
        url: `api/csp/mgr/v1/usage/devices/${this.currentItem.id}`,
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$message({
            type: 'success',
            message: '设备添加成功！',
            duration: 1000
          })
          this.usageAddDialogVisible = false
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import '@/assets/scss/variable.scss';
.js-system-usage-installAccount {
  .js-common-content {
    .js-table {
      tr{
        cursor: default;
      }
    }
    .text-btn{
      cursor: pointer;
      &:hover{
        color: $jsTheme;
      }
    }
  }
}
</style>
