<template>
  <el-dialog class="js-dialog-form" title="系统提示" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <li>是否确定添加？添加后不可撤销！</li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('usageAdd')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>