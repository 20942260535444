var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-system-usage-installAccount" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("装机量")]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "deviceId", label: "设备ID" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "用户名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtUpdated", label: "上次装机时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status
                          ? _c("span", [_vm._v("已添加")])
                          : _c(
                              "span",
                              {
                                staticClass: "text-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdd(scope.row)
                                  },
                                },
                              },
                              [_vm._v("未添加")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("usage-add", {
        on: { usageAdd: _vm.handleUsageAdd },
        model: {
          value: _vm.usageAddDialogVisible,
          callback: function ($$v) {
            _vm.usageAddDialogVisible = $$v
          },
          expression: "usageAddDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }